/*
 * Can be used to search trough the exerciselist and document pages.
 */
function cardListSearch(thisElem) {
    const input = $(thisElem);
    const filter = $(input).val().toUpperCase();
    const area = $(input).parentsUntil(".row").parent().siblings(".card-search-area");
    const tr = area.find(".document-card");

    let td;
    for (let i = 0; i < tr.length; i += 1) {
        td = $(tr[i]).find(".card-title");
        if ($(td).html().toUpperCase().indexOf(filter.toUpperCase()) > -1) {
            $(tr[i]).removeClass("d-none");
        } else {
            $(tr[i]).addClass("d-none");
        }
    }
}
window.cardListSearch = cardListSearch;
